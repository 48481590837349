

















































import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";

@Component({ components: {} })
export default class extends Vue {
  @Prop()
  private readonly activeMode!: string;

  @Prop()
  private readonly dbAddress!: string;

  getDbName(): string {
    return this.dbAddress
      .replace("https://", "")
      .replace(".net", "")
      .toUpperCase()
      .split(".")
      .reverse()
      .join(" ");
  }
}
