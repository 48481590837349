
























































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { api } from "@/backend";
import NavBarMenu from "@/components/NavBarMenu.vue";

@Component({ components: { NavBarMenu } })
export default class extends Vue {
  private username: string = "";
  private password: string = "";
  private loggingIn: boolean = false;
  private alertMessage: string = "";
  private database: string = "VocaDb";
  private databaseOptions = { VocaDb: "VocaDB", VocaDbBeta: "VocaDB BETA" };
  private databaseAddressOptions = {
    VocaDb: "https://vocadb.net",
    VocaDbBeta: "https://beta.vocadb.net"
  };
  private dbAddress: string = "";

  private async login() {
    this.loggingIn = true;
    try {
      let response = await api.authenticate({
        username: this.username,
        password: this.password,
        database: this.database
      });
      localStorage.setItem("accessToken", response.data.token);
      this.$bvToast.show("success");
    } catch (err) {
      this.$bvToast.show("error");
      this.alertMessage = err.response.data.message;
    } finally {
      this.loggingIn = false;
      localStorage.setItem(
        "dbAddress",
        this.databaseAddressOptions[this.database]
      );
    }
  }

  created(): void {
    let dbAddress = localStorage.getItem("dbAddress");
    if (this.dbAddress == "" && dbAddress != null) {
      this.dbAddress = dbAddress;
    }
  }
}
